@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

/* custom colors  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --bgcolor: #f1f5fd;
  --primary-color: #6f5de7;
  --text-color: #828286;
  --main-text-color: #333333;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}

/* --------------------------------- navbar css style starts 
                        -------------------------------------------------------*/

.navbar-bg {
  background-color: var(--bgcolor);
  padding: 2rem 0;
  z-index: 99;
  position: relative;
}

.navbar-brand {
  font-size: 2.5rem;
}

.navbar li {
  padding: 0 2rem;
}

.navbar li a {
  font-size: 1.7rem;
  font-weight: lighter;
  position: relative;
}

.navbar li a:hover {
  color: var(--primary-color) !important;
  text-shadow: 0 0.2rem 0.3rem rgba(183, 171, 255, 0.5);
}

.btn-style {
  width: 12rem;
  height: 4rem;
  border-radius: 2rem;
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
  font-size: 1.6rem;
}

.btn-style:first-child {
  margin-right: 2rem;
}

.btn-style:hover {
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style-border {
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style-border:hover {
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
}

/* --------------------------------- Navbar ends  
                        -------------------------------------------------------*/

/* --------------------------------- Hero sEction  starts  
                        -------------------------------------------------------*/
header {
  background-color: var(--bgcolor);
  min-height: 100vh;
  width: 100%;
}

.main-hero-container {
  height: 80vh;
  display: flex;
  /* background-color: aqua; */
}

.main-hero-container h2 {
  text-transform: capitalize;
  font-weight: bolder;
  font-size:xx-large;
}

.main-hero-para {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 3rem 0 5rem 0;
  font-weight: lighter;
  width: 75%;
}

.main-hero-container h3 {
  font-weight: lighter;
}

.input-group {
  position: relative !important;
}

.form-control-text {
  position: absolute;
  height: 5rem;
  border: none;
  outline-style: none;
  padding-left: 1.5rem !important;
  font-size: 1.4rem;
  font-weight: lighter;
}

.input-group-button {
  position: absolute;
  right: 40%;
  top: 0.5rem;
  color: #fff;
  background-color: var(--primary-color);
  height: 4rem;
  width: 15%;
  border-radius: 2rem !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

/* right side of hero section  */

.main-herosection-images {
  position: relative;
}

.main-herosection-images img {
  width: 50%;
  height: 70%;
  margin-top: 10rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;
  position: absolute;
}

.main-herosection-images .main-hero-img2 {
  position: absolute;
  width: 40%;
  height: 25%;
  left: 60%;
  top: -2%;
  box-shadow: 0.8rem 0 0.8rem rgba(0, 0, 0, 0.25);
}

/* --------------------------------- Hero sEction  ends  
                        -------------------------------------------------------*/

/* --------------------------------- Work Section below hero section   starts  
                        -------------------------------------------------------*/

.work-container {
  width: 80vw;
  padding: 5rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  margin-top: -10rem;
  background-color: #fff;
  border-radius: 0.5rem;
}

.work-container .main-heading {
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 4rem;
  word-spacing: 1rem;
}

.work-container-subdiv {
  padding: 0 6rem;
}

.work-container .sub-heading {
  font-size: 2rem;
  font-weight: normal;
}

.fontawesome-style {
  font-size: 2.3rem;
  color: var(--primary-color);
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--bgcolor);
  display: grid;
  place-items: center;
  text-align: center;
  margin: 2rem auto;
}
/* --------------------------------- Work Section below hero section   ends  
                        -------------------------------------------------------*/

/* --------------------------------- About Us Section STarts  
                        -------------------------------------------------------*/
.common-section {
  margin: 5rem 0;
}

.our-services img {
  width: 40%;
  height: 75%;
  margin-top: 10rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.8rem 0.8rem;
  border-radius: 1.5rem;
}

.our-services .mini-title {
  text-transform: uppercase;
  font-weight: normal;
  margin: 3rem 0;
  text-align: left;
  font-size: 1.5rem;
}

.our-services .main-heading {
  font-weight: bolder;
}

.our-services-info {
  margin-top: 4rem;
}

.our-services .our-services-number {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: transparent;
  border: 0.1rem solid var(--primary-color);
  font-size: 1.5rem;
  color: var(--primary-color);
  display: grid;
  place-items: center;
}

.our-services-data {
  padding-left: 2rem;
}

.our-services .our-services-data p {
  margin: 1rem 0;
}

/* 2nd section of about us  */

.our-services-rightside {
  margin-top: 10rem;
}

.our-services-rightside-content {
  padding-left: 8rem;
}

/* --------------------------------- About Us Section ends  
                        -------------------------------------------------------*/
/* --------------------------------- Services Section starts  
                        -------------------------------------------------------*/

.service-main-container {
  margin-top: 5rem;
  padding: 5rem 0;
}

.service-main-container .main-heading {
  margin: 3rem 0;
}

.work-container-subdiv {
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.16);
}

.work-container-subdiv:hover {
  background-color: var(--bgcolor);
  cursor: pointer;
}

.work-container-subdiv:hover > .fontawesome-style {
  background-color: #fff;
}

.work-container-subdiv .main-hero-para {
  width: 100%;
}

.service-main-container .work-container-subdiv .fontawesome-style {
  text-align: left;
  margin: 2rem 0;
}

@media (min-width: 1400px) {
  .service-container .col-xxl-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .service-container .col-lg-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: 2.5rem auto;
  }
}

/* --------------------------------- Services Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- Contact US Section starts  
                        -------------------------------------------------------*/
.contactus-section {
  margin: 15rem 0;
}

.contact-leftside img {
  min-width: 80%;
  max-height: 30rem;
  height: auto;
}

.contact-input-feild {
  margin-bottom: 4rem;
}

.contact-rightside form .form-control {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  height: 5rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
  width: 2rem;
  height: 2rem;
}

.form-checkbox-style {
  margin: 4rem 0;
}

.contact-rightside form .main-hero-para {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0;
  font-weight: lighter;
  width: 75%;
  padding: 0 2rem;
}
/* --------------------------------- Contact US Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- Footer  Section starts  
                        -------------------------------------------------------*/

footer {
  padding: 5rem 0;
  background-color: #182334;
  color: #fff;
}

footer h2 {
  color: #fff;
}

footer li {
  list-style: none;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #fff;
  cursor: pointer;
  margin: 1rem 0;
}

footer li:hover {
  color: var(--primary-color);
}

footer .fontawesome-style {
  font-size: 2.3rem;
  color: var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 2rem 0;
  cursor: pointer;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear infinite alternate-reverse;
  color: #fff;
}

@keyframes rotatefont {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1.2);
  }
}

footer .main-hero-para {
  margin: 0;
}

/* --------------------------------- Footer  Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- 404 Error Page Section 
                        -------------------------------------------------------*/

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Maven Pro", sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: "Maven Pro", sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  .notfound h2 {
    font-size: 26px;
  }
}

/* --------------------------------- 404 Error Page Section  Ends
                        -------------------------------------------------------*/

/* --------------------------------- responsive media queries  
                        -------------------------------------------------------*/
@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px !important;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}
.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.faq-list {
  border-top: 1px solid #ccc;
}

.faq-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f9f9f9;
}

.faq-question {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0056b3;
}

.faq-toggle-icon {
  font-weight: bold;
  font-size: 24px;
}

.faq-answer {
  padding: 10px 0;
  color: #555;
  font-size: 16px;
}

@media(max-width: 998px){
  .navbar .navbar-collapse{
    margin-top: 1rem;
    background-color: #fff;
    padding: 2rem 1rem;
  }

  .input-group .btn-style{
    font-size: 1.2rem;
  }

  .main-hero-container h1{
    font-size: 2.5rem;
  }

  .main-hero-para{
    font-size: 1.5rem;
    padding-right: 1rem;
    width:100%;
  }

  .main-hero-container {
    display: flow-root;
  }

  .main-hero-container .header-left-side{
    padding-left: 4rem;
    margin-top: 1rem;
  }

  .main-herosection-images{
    position: relative;
    height: 25rem;
  }

  .header-right-side img{
    width: 60%;
    height: 100%;
    margin-top: 0rem;
  }

  .header-right-side .main-hero-img2{
    position: absolute;
    width: 33%;
    height: 33%;
    left: 57%;
    top: -2%;
    box-shadow: 0.8px 0rem 0.8rem rgb(0 0 0/ 25%);
  }

  .input-group .input-group-button{
    right: 26%;
    width: 30%;
  }

  .work-container{
    width: 80vw;
    padding: 0rem 1rem;
    margin-top: 3rem;
  }

  .work-container .main-heading{
    font-size: 2.7rem;
    font-weight: bolder;
    margin-bottom: 3rem;
    word-spacing: 0rem;
    padding-top: 5rem;
  }

  .work-container-subdiv{
    padding: 0 0rem;
  }

  .our-services-list{
    padding-left: 3rem;
    margin-top: 5rem;
  }

  .our-services-rightside-img{
    text-align: center;
  }

  .our-services img{
    width: 70%;
    height: 85%;
  }

  .our-services .our-service-leftside-img img{
    margin-top: 0;
  }

  .service-container .work-container-subdiv{
    padding: 0 2rem;
    margin: 2rem auto;
  }

  .service-container .main-heading{
    margin: -3rem auto 0 auto;
    font-weight: bolder;
  }

  .our-services-rightside-content{
    padding-left: 3rem;
  }

  #wtcu{
    display: none;
  }

  .contactus-section{
    margin: 5rem 0;
  }

  .contact-rightside{
    margin-top: 5rem;
  }

  .contact-rightside form .main-hero-para{
    font-size: 1.3rem;
    color: var(--text-color);
    margin: 0;
    font-weight: lighter;
    width: 98%;
    padding: 0px 1rem;
  }
}